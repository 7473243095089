<template>
  <div>
    <ModalAccountRegisterUpdate
      :show="showInfoRegisterModal"
      @register-submit="onRegisterSubmit"
      @close="closeRegModal"
    />

    <ModalAccountVerificationCode
      v-if="showVerifyEmailCode"
      attribute="email"
      @close="closeAccountVerificationModal"
    />
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: 'ModalAccountMain',
  data() {
    return {
      showVerifyEmailCode: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, [
      'showInfoRegisterModal',
      'openWalletAfterRegister',
      'infoRegisterModalEmailOnly',
    ]),
  },
  methods: {
    onRegisterSubmit() {
      this.showVerifyEmailCode = true;
    },
    closeAccountVerificationModal() {
      this.showVerifyEmailCode = false;
      if (this.infoRegisterModalEmailOnly) {
        this.showInfoRegisterModal = false;
      }
    },
    closeRegModal() {
      const uiStore = useUiStore();
      const authStore = useAuthStore();

      this.showInfoRegisterModal = false;
      if (this.openWalletAfterRegister) {
        setTimeout(() => {
          uiStore.openWalletModal(authStore.wallet ? 'deposit' : 'custodialDeposit');
        }, 500);
      } else {
        this.openWalletAfterRegister = true;
      }
    },
  },
});
</script>
